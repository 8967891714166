import React from "react";
import "./App.css";
import image from "./Screenshot 2024-11-30 214834.png";

const ComingSoon = () => {
  return (
    <div className="coming-soon-body">
      <div className="coming-soon-card">
        <div className="header-logo">
          <img
            src={image} // Replace with your logo path
            alt="Logo"
            className="logo"
          />
        </div>
        <div className="content">
          <h1>Get ready for the change.</h1>
          <p>
            Website coming soon. Please check back to know more. Shoot us an
            email if you're curious.
          </p>
          <button className="email-button">Shoot us an email</button>
        </div>
        <footer>© 2024 Payv3rse Technologies</footer>
      </div>
    </div>
  );
};

export default ComingSoon;
